import { post } from '@/http'
// 提交退换货申请
export function CreatExOrder(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/CreatExOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 修改退换货
export function changedExOrder(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/ChangedExOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
