<template>
    <div>
        <div class="top">
            <div class="title">退款 / 售后</div>
        </div>
        <div class="list">
            <div>
                <table border="0" cellspacing="0" cellpadding="0">
                    <tr class="list-title">
                        <th width="350">商品信息</th>
                        <th width="210">单价 (元)</th>
                        <th width="210">数量</th>
                        <th width="210">优惠</th>
                        <th width="210">实付款(元)</th>
                    </tr>
                </table>

                <!--订单号-->
                <table
                    style="border-collapse:separate; border-spacing:0 10px;"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                >
                    <tr class="order-mian" style="margin:10px">
                        <td width="350">
                            {{ query.OrderID }} 订单号
                            <span class="order-number">
                                {{ query.CreatTime }}</span
                            >
                        </td>
                        <td width="210" v-for="j in 4" :key="j"></td>
                    </tr>
                    <tr class="order-mian-list">
                        <td style="display: flex" class="border">
                            <div>
                                <img :src="pic + query.ProPictrue" alt="" />
                            </div>
                            <div class="order-mian-list-text">
                                <div>{{ query.ProName }}</div>
                                <div class="sku">{{ query.BarCode }}</div>
                            </div>
                        </td>
                        <td class="border">
                            <div>￥{{ query.ProUnitPrice }}</div>
                        </td>
                        <td class="border">
                            <div>X {{ query.PCount }}</div>
                        </td>
                        <td>
                            <div>- {{ query.DisPrice }}</div>
                        </td>
                        <td>
                            <div class="subtotal">
                                <div>￥ {{ query.LastPrice }}</div>
                                <div class="subtotal-tax">
                                    (含税费￥{{ query.TaxPrice }})
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="form">
            <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
            >
                <el-form-item label="退款类型" prop="Type" v-if="type == 2">
                    <el-radio-group v-model="ruleForm.Type" size="small">
                        <el-radio :label="2">仅退款</el-radio>
                        <el-radio :label="1">退货退款</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="货物状态" prop="Type" v-if="type == 2">
                    <el-radio-group v-model="ruleForm.TrackType" size="small">
                        <el-radio :label="1">未收到货</el-radio>
                        <el-radio :label="2">已收到货</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="申请件数" prop="PIDS">
                    <el-input-number
                        size="small"
                        v-model="ruleForm.PIDS"
                        :max="query.PCount"
                        :min="1"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="退款原因" prop="Remark">
                    <el-select
                        v-model="ruleForm.Remark"
                        placeholder="请选择"
                        size="small"
                        style="width: 300px"
                    >
                        <el-option
                            label="不想买了/买错了"
                            value="不想买了/买错了"
                        ></el-option>
                        <el-option
                            label="大小/规格/尺寸等与商品描述不符"
                            value="大小/规格/尺寸等与商品描述不符"
                        ></el-option>
                        <el-option
                            label="包装/商品破损/有异物"
                            value="包装/商品破损/有异物"
                        ></el-option>
                        <el-option
                            label="忘记使用优惠券"
                            value="忘记使用优惠券"
                        ></el-option>
                        <el-option
                            label="发货问题"
                            value="发货问题"
                        ></el-option>
                        <el-option label="其他" value="其他"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="退款金额">
                    <div class="lastPrice">
                        ￥{{
                            (
                                (ruleForm.PIDS / query.PCount) *
                                query.LastPrice
                            ).toFixed(2)
                        }}
                    </div>
                </el-form-item>
                <el-form-item label="问题描述">
                    <el-input
                        style="width: 400px"
                        type="textarea"
                        :rows="3"
                        placeholder="请输入内容"
                        v-model="ruleForm.ExRemark"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="图片附件" v-if="type == 2">
                    <el-upload
                        :action="url"
                        :limit="5"
                        list-type="picture-card"
                        :on-exceed="handleExceed"
                        :data="{
                            directory: 'ExOrder'
                        }"
                        :file-list="fileList"
                        :on-success="handleSuccess"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div class="info" v-if="type == 2">
                为了更好的处理您的申请，请务必上传如下图片：
            </div>
            <div class="info-list" v-if="type == 2">
                <div>
                    1.商品包装完好的图片
                </div>
                <div>
                    2.商品本身完好的图片
                </div>
                <div>
                    3.商品相关配件完好的图片（如说明书、三包卡等）
                </div>
                <div>
                    4.商品发票照片
                </div>
            </div>
        </div>
        <div class="botton" @click="submitForm('ruleForm')">
            <el-button>确认提交</el-button>
        </div>
    </div>
</template>

<script>
import { changedExOrder, CreatExOrder } from './service'

export default {
    data() {
        return {
            dialogImageUrl: '',
            type: 0,
            dialogVisible: false,
            fileList: [],
            url: '',
            ruleForm: {
                PIDS: 1
            },
            rules: {
                Type: [
                    { required: true, message: '请选择内容', trigger: 'change' }
                ],
                TrackType: [
                    { required: true, message: '请选择内容', trigger: 'change' }
                ],
                Remark: [
                    { required: true, message: '请选择内容', trigger: 'change' }
                ],
                PIDS: [
                    { required: true, message: '请选择内容', trigger: 'change' }
                ]
            },
            pic: FILE_URL,
            query: {}
        }
    },
    methods: {
        handleSuccess(response, file, fileList) {
            this.fileList = fileList
        },
        handleRemove(file, fileList) {
            this.fileList = fileList
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleExceed() {
            this.$message.warning(`当前限制选择最多上传5个文件`)
        },
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let Pictrue = ''
                    for (const key in this.fileList) {
                        Pictrue += this.fileList[key].response.data[0].img + ','
                    }
                    let data = {
                        ...this.ruleForm,
                        Type: this.type == 1 ? 2 : this.ruleForm.Type,
                        TrackType: this.type == 1 ? 1 : this.ruleForm.TrackType,
                        OrderID: this.query.OrderID,
                        PIDS: this.query.PID + '|' + this.ruleForm.PIDS
                    }
                    Pictrue = Pictrue.substr(0, Pictrue.length - 1)
                    data.Pictrue = Pictrue

                    if (this.$route.query.ExOrder) {
                        delete data.PIDS
                        delete data.OrderID
                        data.ExOrderID = this.$route.query.ExOrder
                        changedExOrder(data).then(() => {
                            this.$message.success('提交成功')
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/me/sales'
                                })
                            }, 1000)
                        })
                    } else {
                        CreatExOrder(data).then((res) => {
													if(res.code==1){
														this.$message.success('提交成功')
														setTimeout(() => {
														    this.$router.push({
														        path: '/me/sales'
														    })
														}, 1000)
													}else{
														this.$message.warning(res.msg)
													}
                            
                        })
                    }
                } else {
                    return false
                }
            })
        }
    },
    mounted() {
        this.query = this.$route.query
        this.query.LastPrice = parseFloat(this.$route.query.LastPrice)
        this.query.TaxPrice = parseFloat(this.$route.query.TaxPrice)
        this.query.LastPrice = this.query.LastPrice.toFixed(2)
        this.query.TaxPrice = this.query.TaxPrice.toFixed(2)
        this.type = this.$route.query.type
        this.query.PCount = parseInt(this.query.PCount)
        this.url = FILE_URL + '/UploadFile/UploadImg'
    }
}
</script>

<style lang="less" scoped>
.botton {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
        border: none;
        color: #ffffff;
        background: #dc2310;
        border: none;
    }
}

.lastPrice {
    font-family: 'ct';
    color: #dc2310;
    font-size: 17px;
}

.subtotal {
    font-family: 'ct';
    color: #1b98aa;
    font-size: 17px;

    .subtotal-tax {
        font-family: '微软雅黑';
        font-size: 12px;
        color: #999999;
    }
}

.info {
    font-weight: 400;
    color: #666666;
    margin-top: 50px;
    margin-left: 100px;
    font-size: 16px;
}

.form {
    margin-top: 30px;
    background: #fdfdfd;
    border: 1px solid #dcdcdc;
    padding: 30px 0;
    padding-right: 20px;
}

.info-list {
    margin: 10px 0;
    padding-left: 100px;

    div {
        margin: 5px 0;
        color: #999999;
        font-size: 12px;
    }
}

.sku {
    width: fit-content;
    padding: 2px 10px;
    background: #f5f5f5;
    border: 1px solid #dedede;
    border-radius: 15px;
    color: #333333;
    font-size: 12px;
    margin: 10px 0px;
}

.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }

    .policy {
        font-size: 12px;
        color: #0097ba;
        margin-top: 10px;
    }
}

.list {
    margin-top: 10px;

    .list-title {
        background: #eeeeee;
        height: 40px;
        text-align: center;
    }

    .order-mian {
        width: 1300px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        margin: 10px 0;
        background: #f9f9f9;
        padding-left: 10px;
        box-sizing: border-box;
    }

    .order-mian-list {
        text-align: center;
        font-size: 12px;
        margin: 10px 0;
    }

    .order-mian-list-text {
        text-align: left;
        margin: 10px;
    }

    .subtotal {
        font-family: 'ct';
        color: #1b98aa;
        font-size: 17px;
    }

    .button {
        width: fit-content;
        padding: 2px 10px;
        margin: 10px auto;
        background: rgba(27, 152, 170, 0);
        border: 1px solid #d9d9d9;
        color: #999999;
    }

    img {
        width: 100px;
        height: 100px;
    }

    .border {
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;
    }

    .border-left {
        border-left: 1px solid #f4f4f4;
    }

    .border-right {
        border-right: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
    }

    .order-details {
        margin-top: 30px;
    }

    .paidui {
        color: #999999;

        span {
            margin-left: 5px;
        }
    }

    .handle {
        width: fit-content;
        margin: 10px auto;
        padding: 5px 15px;
        color: #ffffff;
        background: #1b98aa;
    }

    .view {
        width: fit-content;
        margin: 10px auto;
        padding: 5px 15px;
        color: #333333;
        background: rgba(238, 238, 238, 0);
        border: 1px solid #dcdcdc;
    }

    .red {
        color: #dc2310;
    }
}
</style>
